import React, { useState } from "react";
import styles from "./LP1L.module.scss";
import { getDefaultColour } from "utils/utils";
import { useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useTranslation } from "react-i18next";
import { Box, Modal } from "@mui/material";
import { IStreamFeedItem } from "types/reducers/homefeed.reducer.type";
import { useNavigate } from "react-router-dom";

interface LP1LCardProps {
  item: IStreamFeedItem;
  image: string;
}

const LP1LCard: React.FC<LP1LCardProps> = ({ item, image }) => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleIsPreviewNoteModalToggle = () => setIsOpen(!isOpen);

  const {
    title,
    subtitle,
    content,
    second_subtitle,
    second_content,
    button,
    button_link,
    button_background_color,
    button_text_color,
  } = item;
  const popupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "#fff",
    boxShadow: 24,
    p: 2,
    outline: "none",
  };

  return (
    <div
      className={styles.cardContainer}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.cardImage}>
        <img src={image} alt="wine" />
      </div>
      <div className={styles.content}>
        <div>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.subtitle}>{subtitle}</p>
          <p className={styles.description}>{content}</p>
          <p className={styles.subtitle}>{second_subtitle}</p>
          <p className={styles.description}>{second_content}</p>
        </div>
        <div className={styles.buttons}>
          <div className={styles.mainButton}>
            <button
              style={{
                color: button_text_color,
                backgroundColor: button_background_color,
              }}
              onClick={() => navigate(button_link)}
            >
              {button}
            </button>
          </div>
          <div className={styles.readMoreLabel} onClick={() => setIsOpen(true)}>
            <span>{translations("label.read_more")}</span>
          </div>
        </div>
      </div>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={popupStyle}>
          <div>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.subtitle}>{subtitle}</p>
            <p className={styles.description}>{content}</p>
            <p className={styles.subtitle}>{second_subtitle}</p>
            <p className={styles.description}>{second_content}</p>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default LP1LCard;
