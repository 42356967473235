import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";

import styles from "./DinoAIChatHistory.module.scss";
import {
  AnswerTouchLineIcon,
  DinoIcon,
  DislikedIcon,
  DislikeIcon,
  InfinityIcon,
  LikedIcon,
  LikeIcon,
  ReplyTouchLine,
  ShareArrowIcon,
} from "assets/icons/icons";

import StorageService from "services/storage.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { saveAsisstantChatToStreamBegin } from "../actions";
import { useNavigate } from "react-router-dom";
import { SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS } from "../constants";

function DinoAIChatHistory() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName },
  } = useAppSelector((state: GlobalIState) => state.HomeFeedReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [reply, setReply] = useState("");
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const dinoChatThreadCookie = () =>
    StorageService.getDataByKey("dinoChatThread");

  const handleAskDinoAI = () => {};

  const handleLikeDislikeAnswer = (type: "like" | "dislike") => {
    setIsLiked(type === "like" ? !isLiked : false);
    setIsDisliked(type === "dislike" ? !isDisliked : false);
  };

  const handleSaveAndClose = () => {
    dispatch(
      saveAsisstantChatToStreamBegin({
        question: dinoChatThreadCookie()?.question,
        answer: dinoChatThreadCookie()?.answer,
      })
    );
  };

  const formatLine = (line: string) => {
    const boldPattern = /\*\*(.*?)\*\*/g;
    const italicPattern = /\*(.*?)\*/g;
    const headerPattern = /^###\s*(.*)$/;

    if (headerPattern.test(line)) {
      const headerText = line.replace(headerPattern, "$1");
      return (
        <h3
          key={line}
          style={{
            color: "#FF6333",
            marginBottom: "12px",
            fontWeight: "bold",
          }}
        >
          {headerText}
        </h3>
      );
    }

    const formattedLine = line
      .replace(boldPattern, (_, boldText) => `<strong>${boldText}</strong>`)
      .replace(italicPattern, (_, italicText) => `<em>${italicText}</em>`);

    return (
      <span
        key={line}
        dangerouslySetInnerHTML={{ __html: formattedLine }}
        style={{ display: "block", marginBottom: "8px" }}
      />
    );
  };

  const formatResponse = (text: string) => {
    if (!text) return null;

    const lines = text.split("\n").filter((line) => line.trim() !== "");

    return lines.map((line, index) => {
      return <p key={index}>{formatLine(line)}</p>;
    });
  };

  useEffect(() => {
    return () => StorageService.deleteCookie("dinoChatThread");
  }, []);

  useEffect(() => {
    if (actionName === SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS) {
      navigate("/home");
    }
  }, [actionName]);

  return (
    <div
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
      className={styles.wrapper}
    >
      <InfinityIcon />

      <div className={styles.chatTimeline}>
        <div className={styles.question}>
          <span>{dinoChatThreadCookie()?.question}</span>
        </div>
        <div className={styles.answer}>
          <div className={styles.answerTouchLine}>
            <AnswerTouchLineIcon />
          </div>

          <div className={styles.formattedAnswer}>
            {formatResponse(dinoChatThreadCookie()?.answer)}
          </div>

          <div className={styles.replyTouchLine}>
            <ReplyTouchLine />
          </div>

          <div className={styles.footer}>
            <div>
              <div onClick={() => handleLikeDislikeAnswer("like")}>
                {isLiked ? <LikedIcon /> : <LikeIcon />}
              </div>
              <div onClick={() => handleLikeDislikeAnswer("dislike")}>
                {isDisliked ? <DislikedIcon /> : <DislikeIcon />}
              </div>
            </div>
            <div className={styles.rightSideButtons}>
              <div>
                <ShareArrowIcon />
                Share
              </div>
              <div>Rewrite</div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.replyBox}>
        <textarea
          value={reply}
          placeholder="..."
          onChange={(e) => setReply(e.target.value)}
        />
      </div>

      <div className={styles.chatActionButtons}>
        <div
          className={styles.saveAndCloseBtn}
          onClick={() => handleSaveAndClose()}
        >
          <span>{translations("label.save_and_close")}</span>
        </div>
        <div
          className={styles.replyToDinoBtn}
          onClick={() => handleAskDinoAI()}
        >
          <DinoIcon />
          <span>{translations("label.ask_dino")}</span>
        </div>
      </div>
      {/* <div style={{ margin: "40px 0px" }}>
        <AnswerBorderSpace />
      </div> */}
    </div>
  );
}

export default DinoAIChatHistory;
