import React from "react";
import styles from "./SP3.module.scss";
import { getDefaultColour } from "utils/utils";
import { useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { IStreamFeedItem } from "types/reducers/homefeed.reducer.type";
import { useNavigate } from "react-router-dom";

interface SP3CardProps {
  item: IStreamFeedItem;
  image: string;
}

const SP3Card: React.FC<SP3CardProps> = ({ item, image }) => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const navigate = useNavigate();
  const {
    title,
    content,
    button,
    button_link,
    button_background_color,
    button_text_color,
    cardColours: { text_hex, background_hex, headline_hex },
  } = item;

  return (
    <div
      className={styles.cardContainer}
      style={
        {
          "--color": getDefaultColour(defaultColour),
          color: text_hex,
          backgroundColor: background_hex,
        } as React.CSSProperties
      }
    >
      <div className={styles.cardImage}>
        <img src={image} alt="wine" />
      </div>
      <div className={styles.content}>
        <div>
          <h2 style={{ color: headline_hex }} className={styles.title}>
            {title}
          </h2>
          <p className={styles.description}>{content}</p>
        </div>
        <div className={styles.mainButton}>
          <button
            style={{
              color: button_text_color,
              backgroundColor: button_background_color,
            }}
            onClick={() => navigate(button_link)}
          >
            {button}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SP3Card;
