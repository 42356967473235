import { useTranslation } from "react-i18next";
import styles from "./ConvertMedals.module.scss";

import { GlobalIState } from "types/RootState";
import { useAppDispatch, useAppSelector } from "hooks";
import { formatPrice, getDefaultColour } from "utils/utils";

import { useNavigate, useSearchParams } from "react-router-dom";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import ButtonComponent from "components/Button/Button.component";
import { submitConvertMedalsBegin } from "pages/Investments/actions";
import { SUBMIT_CONVERT_MEDALS_SUCCESS } from "pages/Investments/constants";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { openToaster, setRightPartItem } from "App/actions";
import ConvertMedalsSuccess from "./ConvertMedalsSuccess/ConvertMedalsSuccess.page";

export default function ConvertMedals() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName },
  } = useAppSelector((state: GlobalIState) => state.InvestmentsReducer);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const { t: translations } = useTranslation();
  const [searchParams] = useSearchParams();
  const [amount, setAmount] = useState("");
  const [destination, setDestination] = useState("0");
  const typeParam = searchParams.get("type");
  const convertingToCHFAmount = formatPrice(
    (destination === "Investment"
      ? Number(amount) * 0.005
      : Number(amount) * 0.01
    )
      .toFixed(2)
      .toString()
  );

  const handleSubmit = () => {
    if (typeParam === "topup" && Number(amount) < 25) {
      dispatch(
        openToaster(
          "ERROR",
          translations(
            "label.the_minimum_amount_to_convert_for_investments_is_25chf"
          )
        )
      );
      return;
    }

    if (Number(amount) <= 0) {
      dispatch(
        openToaster(
          "ERROR",
          translations("label.amount_value_should_be_greater_than_zero")
        )
      );
      return;
    }
    dispatch(
      submitConvertMedalsBegin({
        type: destination,
        medals: Number(amount),
      })
    );
  };

  useEffect(() => {
    if (actionName === SUBMIT_CONVERT_MEDALS_SUCCESS) {
      if (isMobile)
        navigate(
          destination === "Investment"
            ? "/wallet/convert-medals/success?type=topup"
            : "/wallet/convert-medals/success"
        );
      else {
        if (typeParam === "topup")
          navigate("/wallet/convert-medals/success?type=topup");
        else dispatch(setRightPartItem(<ConvertMedalsSuccess />));
      }
    }
  }, [actionName]);

  useEffect(() => {
    if (typeParam === "topup") {
      setDestination("Investment");
    }
  }, [typeParam]);

  return (
    <div
      className={styles.medalsContainer}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <h2>{translations("label.convert")}</h2>
      <span>{translations("label.medals_amount_to_convert_subtitle")}</span>

      <div className={styles.inputs}>
        <TextField
          name="location"
          label={translations("label.amount")}
          variant="filled"
          type="number"
          value={amount}
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{
            style: {
              color: "#000",
              fontFamily: "Montserrat-Regular",
              fontSize: 12,
              transition: ".1s ease-in-out",
            },
          }}
          sx={{
            outline: "none",
            fontSize: 16,
            fontFamily: "Montserrat-Medium",
          }}
          onChange={(e) => {
            if (e.target.value?.length > 10) return;
            else setAmount(e.target.value);
          }}
        />
        <div
          className={styles.maxAmountButton}
          onClick={() => setAmount(client?.convertable_medals.toString())}
        >
          <span>{translations("label.max_amount")}</span>
        </div>
      </div>

      <div className={styles.calculations}>
        <span>
          {translations("label.available_medals") + ": "}{" "}
          <b>{formatPrice(Number(client?.convertable_medals).toString())}</b>
        </span>
      </div>

      <div
        style={
          typeParam === "topup"
            ? { pointerEvents: "none", cursor: "default" }
            : {}
        }
        className={styles.selectBox}
      >
        <label className={styles.activeLabel} htmlFor="destination">
          {translations("label.destination")}
        </label>
        <select
          name={"destination"}
          value={destination}
          className={styles.selectInput}
          onChange={(e) => setDestination(e.target.value)}
        >
          <option value="0" disabled>
            {/* {translations("label.country")} */}
          </option>

          <option value={"Avaliable Benefits"}>
            {translations("label.available_credit_for_wine_purchases")}
          </option>

          <option value={"Investment"}>
            {translations("label.topup_wine_investments")}
          </option>
        </select>
      </div>

      {destination !== "0" ? (
        <span className={styles.convertingToLabel}>
          {translations("label.converting_to")}{" "}
          <b>CHF {convertingToCHFAmount}</b>
        </span>
      ) : null}

      <ButtonComponent
        label={translations("button.submit")}
        class={"btn btn-primary"}
        disabled={
          amount === "" ||
          Number(amount) > client?.convertable_medals ||
          destination === "0"
        }
        handleClick={() => handleSubmit()}
      />
    </div>
  );
}
