import { produce } from "immer";
import { GamificationReducerReducerInterface } from "types/reducers/gamification.reducer.type";
import { HomeFeedReducerInterface } from "types/reducers/homefeed.reducer.type";
import {
  GET_STREAM_FEED_BEGIN,
  GET_STREAM_FEED_FAILED,
  GET_STREAM_FEED_SUCCESS,
  CALL_AI_ASISSTANT_BEGIN,
  CALL_AI_ASISSTANT_FAILED,
  CALL_AI_ASISSTANT_SUCCESS,
  SUBMIT_STREAM_POLL_BEGIN,
  SUBMIT_STREAM_POLL_FAILED,
  SUBMIT_STREAM_POLL_SUCCESS,
  CLEAR_DINO_AI_RESPONSE,
  SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN,
  SAVE_ASISSTANT_CHAT_TO_STREAM_FAILED,
  SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS,
} from "./constants";

const initialState: HomeFeedReducerInterface = {
  action: {
    isLoading: false,
    error: null,
    message: "",
    isSuccess: false,
    actionName: null,
  },
  question: "",
  asisstantAnswer: "",
  streamFeed: [],
  has_answered: false,
};

const HomeFeedReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CALL_AI_ASISSTANT_BEGIN:
      case GET_STREAM_FEED_BEGIN:
      case SUBMIT_STREAM_POLL_BEGIN:
      case SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN:
        draft.action.isLoading = true;
        draft.action.isSuccess = false;
        draft.action.error = null;
        draft.action.actionName = action.type;
        break;
      case CALL_AI_ASISSTANT_FAILED:
      case GET_STREAM_FEED_FAILED:
      case SUBMIT_STREAM_POLL_FAILED:
      case SAVE_ASISSTANT_CHAT_TO_STREAM_FAILED:
        draft.action.isLoading = false;
        draft.action.error = action.payload ? action.payload : "Unknown error";
        draft.action.isSuccess = false;
        draft.action.actionName = action.type;
        break;
      case CALL_AI_ASISSTANT_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.asisstantAnswer = action.payload;
        break;
      case GET_STREAM_FEED_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.streamFeed = null;
        draft.streamFeed = action.payload?.cards;
        draft.has_answered = action.payload.has_answered;
        break;
      case SUBMIT_STREAM_POLL_SUCCESS:
      case SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case CLEAR_DINO_AI_RESPONSE:
        draft.question = "";
        draft.asisstantAnswer = "";
        break;
      default:
        break;
    }
  });

export default HomeFeedReducer;
