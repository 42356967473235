import React, { ReactNode, memo, useState } from "react";
import styles from "./CheckboxRoundStandard.module.scss";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";

interface Props {
  id?: string;
  checked?: boolean;
  label?: string;
  secondLabel?: any;
  thirdLabel?: string;
  fourthLabel?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const CheckboxRoundStandard: React.FC<Props> = (props) => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { t: translations } = useTranslation();

  return (
    <div
      className={styles.container}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.round}>
        <input
          type="checkbox"
          id={props.id ? props.id : "checkbox"}
          checked={props.checked}
          {...props}
        />
        <label htmlFor={props.id ? props.id : "checkbox"}></label>
      </div>
      {props?.secondLabel || props?.thirdLabel ? (
        <div className={styles.threeLabelsBox}>
          <label htmlFor={props.id ? props.id : "checkbox"}>
            {props.label}
          </label>
          {props?.secondLabel ? (
            <label htmlFor={props.id ? props.id : "checkbox"}>
              {props.secondLabel}
            </label>
          ) : null}
          {props?.thirdLabel ? (
            <label htmlFor={props.id ? props.id : "checkbox"}>
              {props.thirdLabel}
            </label>
          ) : null}
        </div>
      ) : props?.fourthLabel ? (
        <div className={styles.roseGlowCheckbox}>
          <label htmlFor={props.id ? props.id : "checkbox"}>
            {props.label}
          </label>
          <label
            htmlFor={props.id ? props.id : "checkbox"}
            className={styles.roseGlowLabel}
          >
            {/* {props.fourthLabel} */}
          </label>
        </div>
      ) : (
        <label
          htmlFor={props.id ? props.id : "checkbox"}
          className={styles.checkboxLabel}
        >
          {props.label}
        </label>
      )}
    </div>
  );
};

export default memo(CheckboxRoundStandard);
