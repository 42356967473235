import React, { useEffect } from "react";
import { Outlet, useSearchParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import styles from "./DefaultWrapper.module.scss";
import VinfinityLogo from "assets/images/VinfinityLogo.png";

import { useAppDispatch, useAppSelector } from "hooks";

import { closeToaster } from "App/actions";

import { GlobalIState } from "types/RootState";
import { Snackbar } from "@mui/material";
import { getDefaultBackground } from "utils/utils";
import ModalComponent from "components/Modal/Modal.component";

const DefaultWrapper: React.FC<any> = () => {
  const { toaster } = useAppSelector(
    (state: GlobalIState) => state.globalReducer
  );
  const { defaultBackground } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const { t: translations } = useTranslation();

  const handlecloseToaster = () => {
    dispatch(closeToaster());
  };

  // useEffect(() => {
  //   if (isToasterOpen) {
  //     setTimeout(function () {
  //       dispatch(closeToaster());
  //     }, 4000);
  //   }
  // }, [isToasterOpen]);

  return (
    <>
      <div
        className={styles.mainWrapper}
        style={
          window.location.pathname.includes("/ask-dino")
            ? {}
            : {
                backgroundImage: `url(${getDefaultBackground(
                  defaultBackground
                )})`,
              }
        }
      >
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
      {/* <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isToasterOpen}
        key={"bottom" + "center"}
        // autoHideDuration={3000}
        message={toasterMessage}
        onClose={handlecloseToaster}
      /> */}
      <ModalComponent />
    </>
  );
};
export default DefaultWrapper;
