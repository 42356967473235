import { openToaster } from "App/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import * as homefeedService from "services/homefeed.service";

import {
  getStreamFeedFailed,
  getStreamFeedSuccess,
  callAIQuestionSuccess,
  callAIQuestionFailed,
  submitStreamPollFailed,
  submitStreamPollSuccess,
  saveAsisstantChatToStreamSuccess,
  saveAsisstantChatToStreamFailed,
} from "./actions";
import {
  GET_STREAM_FEED_BEGIN,
  CALL_AI_ASISSTANT_BEGIN,
  SUBMIT_STREAM_POLL_BEGIN,
  SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN,
} from "./constants";
import axios from "axios";

export function* callAIAsisstant(action) {
  try {
    const response = yield call(
      homefeedService.callAIAsisstant,
      action.payload
    );
    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(callAIQuestionSuccess(response?.data?.data));
    } else {
      yield put(callAIQuestionFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(callAIQuestionFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getStreamFeed(action) {
  try {
    const response = yield call(homefeedService.getStreamFeed, action.payload);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getStreamFeedSuccess(response?.data?.data));
    } else {
      yield put(getStreamFeedFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getStreamFeedFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* submitStreamPoll(action) {
  try {
    const response = yield call(
      homefeedService.submitStreamPoll,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(submitStreamPollSuccess(response?.data?.data));
    } else {
      yield put(submitStreamPollFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(submitStreamPollFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* saveAsisstantChatToStream(action) {
  try {
    const response = yield call(
      homefeedService.saveAsisstantChatToStream,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(saveAsisstantChatToStreamSuccess(response?.data?.data));
    } else {
      yield put(saveAsisstantChatToStreamFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(saveAsisstantChatToStreamFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export default function* homefeedSaga() {
  yield takeLatest(CALL_AI_ASISSTANT_BEGIN, callAIAsisstant);
  yield takeLatest(GET_STREAM_FEED_BEGIN, getStreamFeed);
  yield takeLatest(SUBMIT_STREAM_POLL_BEGIN, submitStreamPoll);
  yield takeLatest(
    SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN,
    saveAsisstantChatToStream
  );
}
