import {
  GET_STREAM_FEED_BEGIN,
  GET_STREAM_FEED_FAILED,
  GET_STREAM_FEED_SUCCESS,
  CALL_AI_ASISSTANT_BEGIN,
  CALL_AI_ASISSTANT_FAILED,
  CALL_AI_ASISSTANT_SUCCESS,
  SUBMIT_STREAM_POLL_BEGIN,
  SUBMIT_STREAM_POLL_SUCCESS,
  CLEAR_DINO_AI_RESPONSE,
  SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN,
  SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS,
  SAVE_ASISSTANT_CHAT_TO_STREAM_FAILED,
} from "./constants";

export const callAIQuestionBegin = (question: string) => ({
  type: CALL_AI_ASISSTANT_BEGIN,
  payload: question,
});

export const callAIQuestionSuccess = (data) => ({
  type: CALL_AI_ASISSTANT_SUCCESS,
  payload: data,
});

export const callAIQuestionFailed = (error) => ({
  type: CALL_AI_ASISSTANT_FAILED,
  payload: error,
});

export const getStreamFeedBegin = (data: {
  isLoggedIn: boolean;
  lang: string;
}) => ({
  type: GET_STREAM_FEED_BEGIN,
  payload: data,
});

export const getStreamFeedSuccess = (data) => ({
  type: GET_STREAM_FEED_SUCCESS,
  payload: data,
});

export const getStreamFeedFailed = (error) => ({
  type: GET_STREAM_FEED_FAILED,
  payload: error,
});

export const submitStreamPollBegin = (data: {
  card_id: number;
  answer: string;
}) => ({
  type: SUBMIT_STREAM_POLL_BEGIN,
  payload: data,
});

export const submitStreamPollSuccess = (data) => ({
  type: SUBMIT_STREAM_POLL_SUCCESS,
  payload: data,
});

export const submitStreamPollFailed = (error) => ({
  type: SUBMIT_STREAM_POLL_SUCCESS,
  payload: error,
});

export const clearDinoAIResponse = () => ({
  type: CLEAR_DINO_AI_RESPONSE,
});

export const saveAsisstantChatToStreamBegin = (data: {
  question: string;
  answer: string;
}) => ({
  type: SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN,
  payload: data,
});

export const saveAsisstantChatToStreamSuccess = (data) => ({
  type: SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS,
  payload: data,
});

export const saveAsisstantChatToStreamFailed = (error) => ({
  type: SAVE_ASISSTANT_CHAT_TO_STREAM_FAILED,
  payload: error,
});
