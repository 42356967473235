import { call, put, takeLatest } from "redux-saga/effects";

import {
  fetchClientBalanceSimulationFailed,
  fetchClientBalanceSimulationSuccess,
  fetchGeographicAllocationFailed,
  fetchGeographicAllocationSuccess,
  fetchLatestTransactionsFailed,
  fetchLatestTransactionsSuccess,
  fetchOutlookSimulationFailed,
  fetchOutlookSimulationSuccess,
  fetchTransactionDetailsFailed,
  fetchTransactionDetailsSuccess,
  fetchUserTransactionsFailed,
  fetchUserTransactionsSuccess,
  fetchWineCollectionFailed,
  fetchWineCollectionSuccess,
  fetchWineDetailsFailed,
  fetchWineDetailsSuccess,
  fetchWinePerformanceFailed,
  fetchWinePerformanceSuccess,
  fetchWinesFailed,
  fetchWinesSuccess,
  getWeeklyPerformanceGuestFailed,
  getWeeklyPerformanceGuestSuccess,
  paypalUserTopUpFailed,
  paypalUserTopUpSubscriptionFailed,
  paypalUserTopUpSubscriptionSuccess,
  paypalUserTopUpSuccess,
  stripeUserTopUpFailed,
  stripeUserTopUpSubscriptionFailed,
  stripeUserTopUpSubscriptionSuccess,
  stripeUserTopUpSuccess,
  submitConvertMedalsBegin,
  submitConvertMedalsFailed,
  submitConvertMedalsSuccess,
  updateTransactionsStatusFailed,
  updateTransactionsStatusSuccess,
} from "./actions";
import * as investmentsService from "services/investments.service";
import {
  FETCH_CLIENT_BALANCE_SIMULATION_BEGIN,
  FETCH_GEOGRAPHIC_ALLOCATION_BEGIN,
  FETCH_LATEST_TRANSACTIONS_BEGIN,
  FETCH_OUTLOOK_SIMULATION_BEGIN,
  FETCH_TRANSACTION_DETAILS_BEGIN,
  FETCH_USER_TRANSACTIONS_BEGIN,
  FETCH_WINES_BEGIN,
  FETCH_WINE_COLLECTION_BEGIN,
  FETCH_WINE_DETAILS_BEGIN,
  FETCH_WINE_PERFORMANCE_BEGIN,
  GET_WEEKLY_PERFORMANCE_CLIENT_BEGIN,
  GET_WEEKLY_PERFORMANCE_GUEST_BEGIN,
  PAYPAL_SUBSCRIPTION_SUCCESS_PAYMENT,
  PAYPAL_SUCCESS_PAYMENT,
  PAYPAL_USER_TOP_UP_BEGIN,
  PAYPAL_USER_TOP_UP_SUBSCRIPTION_BEGIN,
  STRIPE_USER_TOP_UP_BEGIN,
  STRIPE_USER_TOP_UP_SUBSCRIPTION_BEGIN,
  SUBMIT_CONVERT_MEDALS_BEGIN,
  UPDATE_TRANSACTIONS_STATUS_BEGIN,
} from "./constants";
import { openToaster } from "App/actions";
import StorageService from "services/storage.service";
import { getClientBegin } from "pages/Profile/actions";

export function* stripeTopUpUser(action) {
  try {
    const response = yield call(investmentsService.userTopUpByStripe, {
      price: action.payload.price,
      currency: action.payload.currency,
      email: action.payload.email,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getClientBegin());
      yield put(stripeUserTopUpSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(stripeUserTopUpFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(stripeUserTopUpFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}
export function* stripeSubscriptionTopUpUser(action) {
  try {
    const response = yield call(
      investmentsService.userTopUpSubscriptionByStripe,
      {
        price: action.payload.price,
        currency: action.payload.currency,
        email: action.payload.email,
        recurring_payment_date: action.payload.recurring_payment_date,
      }
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getClientBegin());
      yield put(stripeUserTopUpSubscriptionSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(stripeUserTopUpSubscriptionFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(stripeUserTopUpSubscriptionFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* paypalUserTopUp(action) {
  try {
    const response = yield call(investmentsService.userTopUpByPayPal, {
      price: action.payload.price,
      currency: action.payload.currency,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getClientBegin());
      yield put(paypalUserTopUpSuccess(response?.data));
    } else {
      yield put(paypalUserTopUpFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(paypalUserTopUpFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* paypalSuccessPayment(action) {
  try {
    const response = yield call(investmentsService.paypalSuccessPayment, {
      payment_id: action.payload.payment_id,
      payer_id: action.payload.payer_id,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      StorageService.setKeyValue("paymentSuccess", true);
      yield put(getClientBegin());
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* paypalUserTopUpSubscription(action) {
  try {
    const response = yield call(
      investmentsService.userTopUpSubscriptionByPayPal,
      {
        price: action.payload.price,
        currency: action.payload.currency,
        recurring_payment_date: action.payload.recurring_payment_date,
      }
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getClientBegin());
      yield put(paypalUserTopUpSubscriptionSuccess(response?.data));
    } else {
      yield put(paypalUserTopUpSubscriptionFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(paypalUserTopUpSubscriptionFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* paypalSubscriptionSuccessPayment(action) {
  try {
    const response = yield call(
      investmentsService.paypalSubscriptionSuccessPayment,
      {
        agreement_id: action.payload.agreement_id,
      }
    );

    if (response?.data && response?.data.httpStatusCode === 200) {
      StorageService.deleteCookie("agreementId");
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* fetchUserTransactionsCall(action) {
  try {
    const response = yield call(
      investmentsService.fetchUserTransactions,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(fetchUserTransactionsSuccess(response?.data?.data));
    } else {
      yield put(fetchUserTransactionsFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(fetchUserTransactionsFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* fetchTransactionDetailsCall(action) {
  try {
    const response = yield call(
      investmentsService.fetchTransactionDetails,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(fetchTransactionDetailsSuccess(response?.data?.data));
    } else {
      yield put(fetchTransactionDetailsFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(fetchTransactionDetailsFailed(e?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* fetchLatestTransactions() {
  try {
    const response = yield call(investmentsService.fetchLatestTransactions);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(fetchLatestTransactionsSuccess(response?.data?.data));
    } else {
      yield put(fetchLatestTransactionsFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(fetchLatestTransactionsFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* fetchWineCollectionCall() {
  try {
    const response = yield call(investmentsService.fetchWineCollection);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(fetchWineCollectionSuccess(response?.data?.data));
    } else {
      yield put(fetchWineCollectionFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(fetchWineCollectionFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* fetchWines() {
  try {
    const response = yield call(investmentsService.fetchWines);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(fetchWinesSuccess(response?.data?.data));
    } else {
      yield put(fetchWinesFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(fetchWinesFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* fetchWineDetails(action) {
  try {
    const response = yield call(
      investmentsService.fetchWineDetails,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(fetchWineDetailsSuccess(response?.data?.data));
    } else {
      yield put(fetchWineDetailsFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(fetchWineDetailsFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* fetchClientBalanceSimulation(action) {
  try {
    const response = yield call(
      investmentsService.fetchClientBalanceSimulation
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(
        fetchClientBalanceSimulationSuccess(response?.data?.data?.result)
      );
    } else {
      yield put(fetchClientBalanceSimulationFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(fetchClientBalanceSimulationFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* fetchOutlookSimulation(action) {
  try {
    const response = yield call(
      investmentsService.fetchOutlookSimulation,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(fetchOutlookSimulationSuccess(response?.data?.data));
    } else {
      yield put(fetchOutlookSimulationFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(fetchOutlookSimulationFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* fetchGeographicAllocation(action) {
  try {
    const response = yield call(investmentsService.fetchGeographicAllocation);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(fetchGeographicAllocationSuccess(response?.data?.data));
    } else {
      yield put(fetchGeographicAllocationFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(fetchGeographicAllocationFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* fetchWinePerformance(action) {
  try {
    const response = yield call(
      investmentsService.fetchWinePerformace,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(fetchWinePerformanceSuccess(response?.data?.data));
    } else {
      yield put(fetchWinePerformanceFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(fetchWinePerformanceFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* fetchWeeklyPerformanceGuest(action) {
  try {
    const response = yield call(
      investmentsService.fetchWeeklyPerformance,
      false
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getWeeklyPerformanceGuestSuccess(response?.data?.data));
    } else {
      yield put(getWeeklyPerformanceGuestFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getWeeklyPerformanceGuestFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* fetchWeeklyPerformanceClient(action) {
  try {
    const response = yield call(
      investmentsService.fetchWeeklyPerformance,
      true
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getWeeklyPerformanceGuestSuccess(response?.data?.data));
    } else {
      yield put(getWeeklyPerformanceGuestFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getWeeklyPerformanceGuestFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* updateTransactionsStatus(action) {
  try {
    const response = yield call(
      investmentsService.updateTransactionsStatusBegin
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(updateTransactionsStatusSuccess(response?.data?.data?.result));
    } else {
      yield put(updateTransactionsStatusFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(updateTransactionsStatusFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* submitConvertMedals(action) {
  try {
    const response = yield call(
      investmentsService.submitConvertMedals,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(submitConvertMedalsSuccess(response?.data?.data));
    } else {
      yield put(submitConvertMedalsFailed(response?.response));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(submitConvertMedalsFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export default function* investmentsSaga() {
  yield takeLatest(STRIPE_USER_TOP_UP_BEGIN, stripeTopUpUser);
  yield takeLatest(
    STRIPE_USER_TOP_UP_SUBSCRIPTION_BEGIN,
    stripeSubscriptionTopUpUser
  );
  yield takeLatest(PAYPAL_USER_TOP_UP_BEGIN, paypalUserTopUp);
  yield takeLatest(PAYPAL_SUCCESS_PAYMENT, paypalSuccessPayment);
  yield takeLatest(
    PAYPAL_USER_TOP_UP_SUBSCRIPTION_BEGIN,
    paypalUserTopUpSubscription
  );
  yield takeLatest(
    PAYPAL_SUBSCRIPTION_SUCCESS_PAYMENT,
    paypalSubscriptionSuccessPayment
  );
  yield takeLatest(FETCH_USER_TRANSACTIONS_BEGIN, fetchUserTransactionsCall);
  yield takeLatest(
    FETCH_TRANSACTION_DETAILS_BEGIN,
    fetchTransactionDetailsCall
  );
  yield takeLatest(FETCH_LATEST_TRANSACTIONS_BEGIN, fetchLatestTransactions);
  yield takeLatest(FETCH_WINE_COLLECTION_BEGIN, fetchWineCollectionCall);
  yield takeLatest(FETCH_WINES_BEGIN, fetchWines);
  yield takeLatest(FETCH_WINE_DETAILS_BEGIN, fetchWineDetails);
  yield takeLatest(
    FETCH_CLIENT_BALANCE_SIMULATION_BEGIN,
    fetchClientBalanceSimulation
  );
  yield takeLatest(FETCH_OUTLOOK_SIMULATION_BEGIN, fetchOutlookSimulation);
  yield takeLatest(
    FETCH_GEOGRAPHIC_ALLOCATION_BEGIN,
    fetchGeographicAllocation
  );
  yield takeLatest(FETCH_WINE_PERFORMANCE_BEGIN, fetchWinePerformance);
  yield takeLatest(
    GET_WEEKLY_PERFORMANCE_GUEST_BEGIN,
    fetchWeeklyPerformanceGuest
  );
  yield takeLatest(
    GET_WEEKLY_PERFORMANCE_CLIENT_BEGIN,
    fetchWeeklyPerformanceClient
  );
  yield takeLatest(UPDATE_TRANSACTIONS_STATUS_BEGIN, updateTransactionsStatus);
  yield takeLatest(SUBMIT_CONVERT_MEDALS_BEGIN, submitConvertMedals);
}
