import React, { useEffect, useState } from "react";
import styles from "./SubmittedPoll.module.scss";
import { getDefaultColour } from "utils/utils";
import { useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useTranslation } from "react-i18next";
import { WineIllustration } from "assets/icons/icons";

interface SubmittedPollProps {
  question: string;
  label: string;
  percentage: number;
  onRetake: () => void;
}

const SubmittedPoll: React.FC<SubmittedPollProps> = ({
  question,
  label,
  percentage,
  onRetake,
}) => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { t: translations } = useTranslation();
  const [percentageWidth, setPercentageWidth] = useState(0);

  useEffect(() => {
    if (percentage && percentageWidth === 0) {
      setPercentageWidth(percentage - 3.5);
    }
  }, [percentage]);

  return (
    <div
      className={styles.cardContainer}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div>
        <h2 className={styles.thankYouMessage}>
          {translations("label.thank_you_for_your_answer")}
        </h2>

        <div className={styles.pollResultsCard}>
          <div>
            <span className={styles.question}>{question}</span>
          </div>
          <div className={styles.pollResult}>
            <div
              className={styles.option}
              style={{ width: `${percentageWidth}%` }}
            >
              <span className={styles.questionLabel}>{label}</span>
              <span className={styles.percentage}>{percentage}%</span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.bottom}>
        <div className={styles.retakePoll} onClick={onRetake}>
          {translations("label.retake_the_poll")}
        </div>
        <div className={styles.icon}>
          <WineIllustration />
        </div>
      </div>
    </div>
  );
};

export default SubmittedPoll;
