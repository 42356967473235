const scope = "vinfinity/investments";

export const STRIPE_USER_TOP_UP_BEGIN = `${scope}/STRIPE_USER_TOP_UP_BEGIN`;
export const STRIPE_USER_TOP_UP_SUCCESS = `${scope}/STRIPE_USER_TOP_UP_SUCCESS`;
export const STRIPE_USER_TOP_UP_FAILED = `${scope}/STRIPE_USER_TOP_UP_FAILED`;

export const STRIPE_USER_TOP_UP_SUBSCRIPTION_BEGIN = `${scope}/STRIPE_USER_TOP_UP_SUBSCRIPTION_BEGIN`;
export const STRIPE_USER_TOP_UP_SUBSCRIPTION_SUCCESS = `${scope}/STRIPE_USER_TOP_UP_SUBSCRIPTION_SUCCESS`;
export const STRIPE_USER_TOP_UP_SUBSCRIPTION_FAILED = `${scope}/STRIPE_USER_TOP_UP_SUBSCRIPTION_FAILED`;

export const PAYPAL_USER_TOP_UP_BEGIN = `${scope}/PAYPAL_USER_TOP_UP_BEGIN`;
export const PAYPAL_USER_TOP_UP_SUCCESS = `${scope}/PAYPAL_USER_TOP_UP_SUCCESS`;
export const PAYPAL_USER_TOP_UP_FAILED = `${scope}/PAYPAL_USER_TOP_UP_FAILED`;

export const PAYPAL_SUCCESS_PAYMENT = `${scope}/PAYPAL_SUCCESS_PAYMENT`;

export const PAYPAL_USER_TOP_UP_SUBSCRIPTION_BEGIN = `${scope}/PAYPAL_USER_TOP_UP_SUBSCRIPTION_BEGIN`;
export const PAYPAL_USER_TOP_UP_SUBSCRIPTION_SUCCESS = `${scope}/PAYPAL_USER_TOP_UP_SUBSCRIPTION_SUCCESS`;
export const PAYPAL_USER_TOP_UP_SUBSCRIPTION_FAILED = `${scope}/PAYPAL_USER_TOP_UP_SUBSCRIPTION_FAILED`;

export const PAYPAL_SUBSCRIPTION_SUCCESS_PAYMENT = `${scope}/PAYPAL_SUBSCRIPTION_SUCCESS_PAYMENT`;

export const FETCH_USER_TRANSACTIONS_BEGIN = `${scope}/FETCH_USER_TRANSACTIONS_BEGIN`;
export const FETCH_USER_TRANSACTIONS_SUCCESS = `${scope}/FETCH_USER_TRANSACTIONS_SUCCESS`;
export const FETCH_USER_TRANSACTIONS_FAILED = `${scope}/FETCH_USER_TRANSACTIONS_FAILED`;

export const FETCH_TRANSACTION_DETAILS_BEGIN = `${scope}/FETCH_TRANSACTION_DETAILS_BEGIN`;
export const FETCH_TRANSACTION_DETAILS_SUCCESS = `${scope}/FETCH_TRANSACTION_DETAILS_SUCCESS`;
export const FETCH_TRANSACTION_DETAILS_FAILED = `${scope}/FETCH_TRANSACTION_DETAILS_FAILED`;

export const FETCH_LATEST_TRANSACTIONS_BEGIN = `${scope}/FETCH_LATEST_TRANSACTIONS_BEGIN`;
export const FETCH_LATEST_TRANSACTIONS_SUCCESS = `${scope}/FETCH_LATEST_TRANSACTIONS_SUCCESS`;
export const FETCH_LATEST_TRANSACTIONS_FAILED = `${scope}/FETCH_LATEST_TRANSACTIONS_FAILED`;

export const FETCH_WINE_COLLECTION_BEGIN = `${scope}/FETCH_WINE_COLLECTION_BEGIN`;
export const FETCH_WINE_COLLECTION_SUCCESS = `${scope}/FETCH_WINE_COLLECTION_SUCCESS`;
export const FETCH_WINE_COLLECTION_FAILED = `${scope}/FETCH_WINE_COLLECTION_FAILED`;

export const FETCH_WINES_BEGIN = `${scope}/FETCH_WINES_BEGIN`;
export const FETCH_WINES_SUCCESS = `${scope}/FETCH_WINES_SUCCESS`;
export const FETCH_WINES_FAILED = `${scope}/FETCH_WINES_FAILED`;

export const FETCH_WINE_DETAILS_BEGIN = `${scope}/FETCH_WINE_DETAILS_BEGIN`;
export const FETCH_WINE_DETAILS_SUCCESS = `${scope}/FETCH_WINE_DETAILS_SUCCESS`;
export const FETCH_WINE_DETAILS_FAILED = `${scope}/FETCH_WINE_DETAILS_FAILED`;

export const FETCH_CLIENT_BALANCE_SIMULATION_BEGIN = `${scope}/FETCH_CLIENT_BALANCE_SIMULATION_BEGIN`;
export const FETCH_CLIENT_BALANCE_SIMULATION_SUCCESS = `${scope}/FETCH_CLIENT_BALANCE_SIMULATION_SUCCESS`;
export const FETCH_CLIENT_BALANCE_SIMULATION_FAILED = `${scope}/FETCH_CLIENT_BALANCE_SIMULATION_FAILED`;

export const FETCH_OUTLOOK_SIMULATION_BEGIN = `${scope}/FETCH_OUTLOOK_SIMULATION_BEGIN`;
export const FETCH_OUTLOOK_SIMULATION_SUCCESS = `${scope}/FETCH_OUTLOOK_SIMULATION_SUCCESS`;
export const FETCH_OUTLOOK_SIMULATION_FAILED = `${scope}/FETCH_OUTLOOK_SIMULATION_FAILED`;

export const FETCH_GEOGRAPHIC_ALLOCATION_BEGIN = `${scope}/FETCH_GEOGRAPHIC_ALLOCATION_BEGIN`;
export const FETCH_GEOGRAPHIC_ALLOCATION_SUCCESS = `${scope}/FETCH_GEOGRAPHIC_ALLOCATION_SUCCESS`;
export const FETCH_GEOGRAPHIC_ALLOCATION_FAILED = `${scope}/FETCH_GEOGRAPHIC_ALLOCATION_FAILED`;

export const FETCH_WINE_PERFORMANCE_BEGIN = `${scope}/FETCH_WINE_PERFORMANCE_BEGIN`;
export const FETCH_WINE_PERFORMANCE_SUCCESS = `${scope}/FETCH_WINE_PERFORMANCE_SUCCESS`;
export const FETCH_WINE_PERFORMANCE_FAILED = `${scope}/FETCH_WINE_PERFORMANCE_FAILED`;

export const GET_WEEKLY_PERFORMANCE_GUEST_BEGIN = `${scope}/GET_WEEKLY_PERFORMANCE_GUEST_BEGIN`;
export const GET_WEEKLY_PERFORMANCE_GUEST_SUCCESS = `${scope}/GET_WEEKLY_PERFORMANCE_GUEST_SUCCESS`;
export const GET_WEEKLY_PERFORMANCE_GUEST_FAILED = `${scope}/GET_WEEKLY_PERFORMANCE_GUEST_FAILED`;

export const GET_WEEKLY_PERFORMANCE_CLIENT_BEGIN = `${scope}/GET_WEEKLY_PERFORMANCE_CLIENT_BEGIN`;
export const GET_WEEKLY_PERFORMANCE_CLIENT_SUCCESS = `${scope}/GET_WEEKLY_PERFORMANCE_CLIENT_SUCCESS`;
export const GET_WEEKLY_PERFORMANCE_CLIENT_FAILED = `${scope}/GET_WEEKLY_PERFORMANCE_CLIENT_FAILED`;

export const UPDATE_TRANSACTIONS_STATUS_BEGIN = `${scope}/UPDATE_TRANSACTIONS_STATUS_BEGIN`;
export const UPDATE_TRANSACTIONS_STATUS_SUCCESS = `${scope}/UPDATE_TRANSACTIONS_STATUS_SUCCESS`;
export const UPDATE_TRANSACTIONS_STATUS_FAILED = `${scope}/UPDATE_TRANSACTIONS_STATUS_FAILED`;

export const CLEAR_LOGGED_IN_GRAPH_DATA = `${scope}/CLEAR_LOGGED_IN_GRAPH_DATA`;
export const CLEAR_TRANSACTIONS = `${scope}/CLEAR_TRANSACTIONS`;

export const SUBMIT_CONVERT_MEDALS_BEGIN = `${scope}/SUBMIT_CONVERT_MEDALS_BEGIN`;
export const SUBMIT_CONVERT_MEDALS_SUCCESS = `${scope}/SUBMIT_CONVERT_MEDALS_SUCCESS`;
export const SUBMIT_CONVERT_MEDALS_FAILED = `${scope}/SUBMIT_CONVERT_MEDALS_FAILED`;
