import { useTranslation } from "react-i18next";
import styles from "./TransactionDetails.module.scss";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

import { GlobalIState } from "types/RootState";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  formatPrice,
  getCurrencyStr,
  getDefaultColour,
  getOrdinalIndicator,
  getStakesLabel,
} from "utils/utils";

import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { useEffect } from "react";
import { fetchTransactionDetailsBegin } from "pages/Investments/actions";
import { openToaster } from "App/actions";

export default function TransactionDetails() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { leftPartShownItem } = useAppSelector(
    (state: GlobalIState) => state.globalReducer
  );
  const {
    action: { isLoading },
    transactionDetails,
  } = useAppSelector((state: GlobalIState) => state.InvestmentsReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [searchParams] = useSearchParams();
  const idParam = searchParams.get("id");

  const handleDownloadInvoice = () => {
    if (transactionDetails?.invoice_url) {
      window.open(transactionDetails?.invoice_url, "_blank");
    } else
      dispatch(openToaster("ERROR", translations("error.no_invoice_document")));
  };

  useEffect(() => {
    if (!transactionDetails && idParam) {
      dispatch(fetchTransactionDetailsBegin(idParam));
    }
  }, [transactionDetails, idParam]);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        className={styles.transactionDetailsWrapper}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        <div className={styles.mainContent}>
          {!window.location.pathname.includes("/wallet") ? (
            <h2>{translations("label.transaction_details")}</h2>
          ) : null}
        </div>

        <div
          className={styles.boxHeader}
          style={{
            padding: window.location.pathname.includes(
              "/wallet/transactions/details"
            )
              ? 20
              : "20px 20px 0px 20px",
          }}
        >
          <div className={styles.hint}>
            <span>
              {dayjs(transactionDetails?.created_at).format("MMMM DD, YYYY")}
            </span>
          </div>
          <div className={styles.title}>
            <span>
              {transactionDetails?.transactionType?.name}
              {transactionDetails?.transactionType?.id === 2
                ? " (" +
                  translations("label.monthly_every") +
                  " " +
                  dayjs(transactionDetails?.subscription_date).get("date") +
                  getOrdinalIndicator(
                    dayjs(transactionDetails?.subscription_date).get("date")
                  ) +
                  ")"
                : null}
            </span>
          </div>
          <div className={styles.price}>
            <span>
              {leftPartShownItem.includes("gaming") ||
              [4, 6].includes(transactionDetails?.transactionType?.id)
                ? ""
                : getCurrencyStr(transactionDetails?.currency)}{" "}
              {transactionDetails?.amount &&
              transactionDetails?.transaction_type == 5
                ? Number(transactionDetails?.amount).toFixed(0)
                : formatPrice(
                    [4, 6].includes(transactionDetails?.transactionType?.id)
                      ? transactionDetails?.amount
                      : Number(
                          transactionDetails?.discounted_amount
                            ? transactionDetails?.discounted_amount
                            : transactionDetails?.amount
                        )?.toFixed(2)
                  )}
            </span>
          </div>
        </div>
        <div className={styles.mainContent}>
          <div className={styles.infoWithLabel}>
            <span className={styles.coloredLabel}>
              {translations(
                [4, 5, 6].includes(transactionDetails?.transactionType?.id)
                  ? "label.from"
                  : "label.recipient"
              )}
            </span>
            <div>
              <span>Vinfinity Schweiz GmbH</span>
              <span>Rigiweg 21A</span>
              <span>6343 Holzhäusern</span>
              <span>Switzerland</span>
            </div>
          </div>
          <div className={styles.infoWithLabel}>
            <span className={styles.coloredLabel}>
              {translations(
                [4, 5, 6].includes(transactionDetails?.transactionType?.id)
                  ? "label.recipient"
                  : "label.from"
              )}
            </span>
            <div>
              <span>
                {client?.firstname} {client?.lastname}
              </span>
              <span>{client?.da_street}</span>
              {leftPartShownItem === "1" || leftPartShownItem === "drinking" ? (
                <span>
                  {client?.da_zip ? client?.da_zip + " " : null}
                  {client?.da_city ? client?.da_city : null}
                </span>
              ) : (
                <span>
                  {client?.da_zip ? client?.da_zip + " " : null}
                  {client?.da_city ? client?.da_city : null}
                </span>
              )}
              <span>
                {client?.countryDelivery?.[`name_${client?.language}`]}
              </span>
              <span>{client?.email}</span>
            </div>
          </div>

          <div className={styles.bottomPart}>
            <span>{translations("label.transaction_reference_number")}</span>
            <br />
            <span>{transactionDetails?.reference_number}</span>
          </div>

          {transactionDetails?.transactionType?.id === 3 ? (
            <div className={styles.buttons}>
              <div
                className={styles.downloadInvoiceBtn}
                style={{
                  width:
                    translations("label.download_invoice")?.length >= 20
                      ? 250
                      : 200,
                }}
                onClick={() => handleDownloadInvoice()}
              >
                <span>{translations("label.download_invoice")}</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </LoaderWrapper>
  );
}
