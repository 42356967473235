import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";

import styles from "./AskDino.module.scss";
import { CloseNoBgIcon, DinoIcon } from "assets/icons/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import axiosInstance from "../../../services/api";
import { callAIQuestionBegin } from "../actions";
import axios from "axios";
import LoaderBounce from "components/Loader/bounce/LoaderBounce.component";
import { CALL_AI_ASISSTANT_SUCCESS } from "../constants";
import StorageService from "services/storage.service";

function AskDino() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName, isLoading },
    asisstantAnswer,
  } = useAppSelector((state: GlobalIState) => state.HomeFeedReducer);
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const [question, setQuestion] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const callAIWithCommonTopic = (topic: string) => {
    setQuestion(topic);

    setTimeout(() => {
      handleAskDinoAI(topic);
    }, 100);
  };

  const handleAskDinoAI = async (topic?: string) => {
    let questionToAI = topic ? topic : question;

    dispatch(callAIQuestionBegin(questionToAI));
  };

  useEffect(() => {
    if (actionName === CALL_AI_ASISSTANT_SUCCESS && asisstantAnswer !== "") {
      StorageService.setKeyValue("dinoChatThread", {
        question: question,
        answer: asisstantAnswer,
      });
      navigate("/chat-history", {
        state: {
          question: question,
          answer: asisstantAnswer,
        },
      });
    }
  }, [actionName]);

  return (
    <div
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
      className={styles.wrapper}
    >
      <div className={styles.header} onClick={() => navigate("/home")}>
        <CloseNoBgIcon />
      </div>
      <div className={styles.title}>
        {" "}
        <div className={styles.helpTitle}>
          <span>
            {translations("label.hi")} {client?.firstname}
          </span>
          <br />
          <span>{translations("label.how_can_i_help?")}</span>
        </div>
        <div></div>
      </div>
      <div className={styles.content}>
        <div className={styles.leftPart}>
          <div className={styles.textBox}>
            <textarea
              style={
                isMobile
                  ? {
                      height: !isTyping ? 90 : 180,
                    }
                  : { height: 200 }
              }
              value={question}
              // placeholder={translations("label.start_typing")}
              onBlur={(e) => setIsTyping(false)}
              onFocus={(e) => setIsTyping(true)}
              onChange={(e) => setQuestion(e.target.value)}
            />
            <div
              className={styles.clearChatIcon}
              onClick={() => setQuestion("")}
            >
              <span>X</span>
            </div>
          </div>
        </div>
        {(isMobile && !isTyping) || !isMobile ? (
          <div className={styles.rightPart}>
            <div>
              <span>{translations("label.most_common_topics")}</span>
            </div>
            <div
              onClick={() =>
                callAIWithCommonTopic(translations("label.common_topics_1"))
              }
            >
              <span>{translations("label.common_topics_1")}</span>
            </div>
            <div
              onClick={() =>
                callAIWithCommonTopic(translations("label.common_topics_2"))
              }
            >
              <span>{translations("label.common_topics_2")}</span>
            </div>
            <div
              onClick={() =>
                callAIWithCommonTopic(translations("label.common_topics_3"))
              }
            >
              <span>{translations("label.common_topics_3")}</span>
            </div>
            <div
              onClick={() =>
                callAIWithCommonTopic(translations("label.common_topics_4"))
              }
            >
              <span>{translations("label.common_topics_4")}</span>
            </div>
          </div>
        ) : null}
      </div>

      {isLoading ? (
        <div className={styles.button}>
          <LoaderBounce />
        </div>
      ) : (
        <div className={styles.button} onClick={() => handleAskDinoAI()}>
          <DinoIcon />
          <span>{translations("label.ask_dino")}</span>
        </div>
      )}
    </div>
  );
}

export default AskDino;
