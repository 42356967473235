import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import styles from "./PP1.module.scss";
import { GlobalIState } from "types/RootState";
import { useAppSelector } from "hooks";
import { useEffect } from "react";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

interface PP1CardProps {
  images: any;
  title: string;
  description: string;
}

const PP1Card: React.FC<PP1CardProps> = ({ images, title, description }) => {
  const { client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const isMobile = useCheckMobileScreen();

  return (
    <div className={`${styles.cardContainer} pp1-card`}>
      <Swiper
        navigation
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination]}
        className={styles.swiper}
      >
        {images.map((item, index) => {
          return (
            <SwiperSlide key={index} className={styles.slide}>
              {item?.video_url?.includes("mp4") ? (
                <video
                  controls={false}
                  autoPlay={true}
                  loop
                  muted
                  webkit-playsinline
                  playsInline
                  src={item?.video_url}
                  className={styles.video}
                >
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={isMobile ? item?.mobile_image_url : item?.image_url}
                  alt={`Slide ${index + 1}`}
                  className={styles.image}
                />
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div
        style={
          isMobile
            ? {
                width: "80%",
                bottom: images[0]?.video_url?.includes("mp4") ? "20px" : "70px",
              }
            : { width: "65%", bottom: "20px" }
        }
        className={styles.content}
      >
        <h2 className={styles.title}>
          {title?.replace("{first_name}", client?.firstname)}
        </h2>
        <p className={styles.subtitle}>{description}</p>
      </div>
      <div className={styles.overlay}></div>
    </div>
  );
};

export default PP1Card;
