import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./MobilePurchasedQuantity.module.scss";
import { BackButtonIcon, ShoppingCartAddIcon } from "assets/icons/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getDefaultColour } from "utils/utils";
import { useTranslation } from "react-i18next";
import { getProductTransactionsBegin } from "pages/PersonalLedger/actions";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import {
  fetchProductDetailsBegin,
  setCartItems,
} from "pages/Purchasing/actions";
import { FETCH_PRODUCT_DETAILS_SUCCESS } from "pages/Purchasing/constants";
import { openToaster } from "App/actions";

function MobilePurchasedQuantity() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const {
    action: { isLoading },
    orders,
    productTransactions,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName },
    productDetails,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const [searchParams] = useSearchParams();
  const productIdParam = searchParams.get("id");
  const [shouldAddToCart, setShouldAddToCart] = useState(false);

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const getTotalAmount = (data) => {
    if (data) {
      return data?.reduce((acc, transaction) => {
        return (
          acc +
          transaction.order.orderDetails.reduce(
            (acc, item) => acc + Number(item.total_price),
            0
          )
        );
      }, 0);
    }
  };

  const totalAmount = getTotalAmount(productTransactions);
  const totalQuantity = productTransactions?.reduce((sum, item) => {
    const orderDetailsQuantity = item?.order?.orderDetails?.reduce(
      (detailsSum, detail) => {
        return detailsSum + (detail.quantity || 0);
      },
      0
    );
    return sum + orderDetailsQuantity;
  }, 0);

  const handleAddToCart = () => {
    dispatch(fetchProductDetailsBegin(productIdParam, getLanguage()));
    setShouldAddToCart(true);
  };

  useEffect(() => {
    dispatch(getProductTransactionsBegin(productIdParam));
  }, []);

  useEffect(() => {
    if (shouldAddToCart && actionName === FETCH_PRODUCT_DETAILS_SUCCESS) {
      dispatch(setCartItems(productDetails));
      dispatch(
        openToaster("SUCCESS", translations("label.item_added_to_cart"))
      );
      setShouldAddToCart(false);
    }
  }, [shouldAddToCart, actionName]);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        className={styles.wrapper}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        <div className={styles.content}>
          <div className={styles.card}>
            <div>
              <h5>{translations("label.purchased_quantity")}</h5>
              <span>
                {productTransactions?.length > 0 ? totalQuantity : null}
              </span>
            </div>
            <div>
              <h5>{translations("label.last_order")}</h5>
              <span>
                {productTransactions?.length > 0
                  ? dayjs(productTransactions[0]?.order?.order_date).format(
                      "DD.MM.YYYY"
                    )
                  : null}
              </span>
            </div>
          </div>

          <div className={styles.tableBox}>
            <table>
              <tr className={styles.tbHeader}>
                {/* <th>{translations("label.ordered_items")}</th>/ */}
                <th>{translations("label.date")}</th>
                <th>{translations("label.quantity")}</th>
                <th>{translations("label.price")}</th>
              </tr>

              {productTransactions?.map((item, index) => {
                return (
                  <tr key={index}>
                    {/* <td>
                      {item?.order?.orderDetails[0]?.product?.wine_name} -{" "}
                      {item?.order?.orderDetails[0]?.product?.bottle_size}
                    </td> */}
                    <td>
                      {dayjs(item?.order?.order_date).format("DD.MM.YYYY")}
                    </td>
                    <td>{item?.order?.orderDetails[0]?.quantity}x</td>
                    <td className={styles.price}>
                      {item?.currency} {Number(item?.amount).toFixed(2)}
                    </td>
                  </tr>
                );
              })}
              <tfoot>
                <tr>
                  <td>{translations("label.total_purchases")}</td>
                  <td></td>
                  <td>CHF {totalAmount?.toFixed(2)}</td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className={styles.mainButton}>
            <div
              className={styles.reorderBtn}
              onClick={() => handleAddToCart()}
            >
              <ShoppingCartAddIcon />
            </div>
          </div>
        </div>
      </div>
    </LoaderWrapper>
  );
}

export default MobilePurchasedQuantity;
