import { useTranslation } from "react-i18next";
import styles from "./ManageWallet.module.scss";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

import { GlobalIState } from "types/RootState";
import { useAppDispatch, useAppSelector } from "hooks";
import { getDefaultColour } from "utils/utils";
import { ArrowRightBlackIcon } from "assets/icons/icons";
import { setRightPartItem } from "App/actions";
import Transactions from "../Transactions/Transactions.page";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  clearTransactions,
  fetchUserTransactionsBegin,
} from "pages/Investments/actions";
import { getCustomerPortalBegin } from "pages/Purchasing/actions";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import properties from "properties";
import { UPDATE_LANGUAGE_SUCCESS } from "pages/Profile/constants";
import ConvertMedals from "../ConvertMedals/ConvertMedals.page";

export default function ManageWallet() {
  const { leftPartShownItem } = useAppSelector(
    (state: GlobalIState) => state.globalReducer
  );
  const {
    action: { actionName },
    defaultColour,
    client,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const { customer_portal_url } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [isLoading, setIsLoading] = useState(false);

  const handleTransactionsListClick = (type: string) => {
    if (isMobile) {
      navigate(`/wallet/transactions?type=${type}`);
    } else {
      dispatch(setRightPartItem(<Transactions type={type} />));
    }
  };

  const handleGetCustomerPortal = () => {
    setIsLoading(true);
    dispatch(getCustomerPortalBegin());
    if (!isMobile) {
      dispatch(setRightPartItem(<LoaderWrapper isLoading={true} />));
    }
  };

  const handleConvertMedals = () => {
    // if()
    dispatch(clearTransactions());
    if (isMobile) navigate("/wallet/convert-medals");
    else dispatch(setRightPartItem(<ConvertMedals />));
  };

  useEffect(() => {
    if (leftPartShownItem === "1" || leftPartShownItem.includes("drinking")) {
      dispatch(fetchUserTransactionsBegin(3));
    } else if (leftPartShownItem.includes("staking")) {
      dispatch(fetchUserTransactionsBegin(1));
    } else if (leftPartShownItem.includes("gaming")) {
      dispatch(fetchUserTransactionsBegin(4));
    }
  }, [leftPartShownItem, actionName === UPDATE_LANGUAGE_SUCCESS]);

  useEffect(() => {
    if (customer_portal_url) {
      window.open(customer_portal_url, "_self");
    }
  }, [customer_portal_url]);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        className={styles.manageWalletWrapper}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        <div className={styles.title}>
          <span>{translations("label.manage_wallet")}</span>
        </div>
        {leftPartShownItem === "1" || leftPartShownItem.includes("drinking") ? (
          <div className={styles.labels}>
            <div onClick={() => handleTransactionsListClick("3")}>
              <span>{translations("label.transaction_list")}</span>
              <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
            </div>
            <div
              // className={properties?.ENV === "dev" ? null : "disabled"}
              onClick={() => handleGetCustomerPortal()}
            >
              <span>{translations("label.manage_credit_card")}</span>
              <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
            </div>
            {/* <div className={properties?.ENV === "dev" ? null : "disabled"}>
            <span>{translations("label.withdraw_money.from_wallet")}</span>
            <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
          </div> */}
          </div>
        ) : null}

        {leftPartShownItem.includes("gaming") ? (
          <div className={styles.labels}>
            <div onClick={() => handleTransactionsListClick("4")}>
              <span>{translations("label.transaction_list")}</span>
              <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
            </div>

            <div onClick={() => handleConvertMedals()}>
              <span>{translations("label.convert_your_medals")}</span>
              <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
            </div>
            {/* <div className={properties?.ENV === "dev" ? null : "disabled"}>
              <span>{translations("label.spend_your_medals")}</span>
              <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
            </div> */}
          </div>
        ) : null}

        {leftPartShownItem.includes("staking") ? (
          <div className={styles.labels}>
            <div onClick={() => handleTransactionsListClick("1")}>
              <span>{translations("label.transaction_list")}</span>
              <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
            </div>
            <div
              // className={properties?.ENV === "dev" ? null : "disabled"}
              onClick={() => handleGetCustomerPortal()}
            >
              <span>{translations("label.manage_credit_card")}</span>
              <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
            </div>
            <div
              // className={properties?.ENV === "dev" ? null : "disabled"}
              onClick={() => handleGetCustomerPortal()}
            >
              <span>{translations("label.change_or_cancel_subscription")}</span>
              <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
            </div>
            {/* <div
            // className={properties?.ENV === "dev" ? null : "disabled"}
            onClick={() => handleGetCustomerPortal()}
          >
            <span>{translations("label.connect_bank_account")}</span>
            <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
          </div> */}
            {/* <div className={properties?.ENV === "dev" ? null : "disabled"}>
            <span>{translations("label.withdraw_money.from_staking")}</span>
            <ArrowRightBlackIcon color={getDefaultColour(defaultColour)} />
          </div> */}
          </div>
        ) : null}
      </div>
    </LoaderWrapper>
  );
}
