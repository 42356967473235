import { useTranslation } from "react-i18next";
import styles from "./ConvertMedalsSuccess.module.scss";
import { GlobalIState } from "types/RootState";
import { useAppDispatch, useAppSelector } from "hooks";
import { getDefaultColour } from "utils/utils";

import { useNavigate, useSearchParams } from "react-router-dom";
import { GreenSuccessRoundedIcon, InfoIcon } from "assets/icons/icons";
import ButtonComponent from "components/Button/Button.component";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

export default function ConvertMedalsSuccess() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const { t: translations } = useTranslation();
  const [searchParams] = useSearchParams();
  const typeParam = searchParams.get("type");

  const handleClose = () => {
    if (isMobile) navigate("/wallet");
    else {
      if (typeParam === "topup") {
        navigate("/investments");
      } else window.location.reload();
    }
  };

  return (
    <div
      className={styles.container}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <h1>{translations("label.medals_conversion_success.title")}</h1>
      <div className={styles.successIcon}>
        <GreenSuccessRoundedIcon />
      </div>

      <div className={styles.infoBox}>
        <div>
          <InfoIcon color="#1A7FDC" />
        </div>
        <div>
          <h3>{translations("label.note")}</h3>
          <span>
            {translations(
              typeParam === "topup"
                ? "label.investments_medals_conversion_success.subtitle"
                : "label.wine_medals_conversion_success.subtitle"
            )}
          </span>
        </div>
      </div>

      <ButtonComponent
        label={translations("button.close")}
        class={"btn btn-primary"}
        handleClick={() => handleClose()}
      />
    </div>
  );
}
