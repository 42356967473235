import {
  CLEAR_LOGGED_IN_GRAPH_DATA,
  CLEAR_TRANSACTIONS,
  FETCH_CLIENT_BALANCE_SIMULATION_BEGIN,
  FETCH_CLIENT_BALANCE_SIMULATION_FAILED,
  FETCH_CLIENT_BALANCE_SIMULATION_SUCCESS,
  FETCH_GEOGRAPHIC_ALLOCATION_BEGIN,
  FETCH_GEOGRAPHIC_ALLOCATION_FAILED,
  FETCH_GEOGRAPHIC_ALLOCATION_SUCCESS,
  FETCH_LATEST_TRANSACTIONS_BEGIN,
  FETCH_LATEST_TRANSACTIONS_FAILED,
  FETCH_LATEST_TRANSACTIONS_SUCCESS,
  FETCH_OUTLOOK_SIMULATION_BEGIN,
  FETCH_OUTLOOK_SIMULATION_FAILED,
  FETCH_OUTLOOK_SIMULATION_SUCCESS,
  FETCH_TRANSACTION_DETAILS_BEGIN,
  FETCH_TRANSACTION_DETAILS_FAILED,
  FETCH_TRANSACTION_DETAILS_SUCCESS,
  FETCH_USER_TRANSACTIONS_BEGIN,
  FETCH_USER_TRANSACTIONS_FAILED,
  FETCH_USER_TRANSACTIONS_SUCCESS,
  FETCH_WINES_BEGIN,
  FETCH_WINES_FAILED,
  FETCH_WINES_SUCCESS,
  FETCH_WINE_COLLECTION_BEGIN,
  FETCH_WINE_COLLECTION_FAILED,
  FETCH_WINE_COLLECTION_SUCCESS,
  FETCH_WINE_DETAILS_BEGIN,
  FETCH_WINE_DETAILS_FAILED,
  FETCH_WINE_DETAILS_SUCCESS,
  FETCH_WINE_PERFORMANCE_BEGIN,
  FETCH_WINE_PERFORMANCE_FAILED,
  FETCH_WINE_PERFORMANCE_SUCCESS,
  GET_WEEKLY_PERFORMANCE_CLIENT_BEGIN,
  GET_WEEKLY_PERFORMANCE_CLIENT_FAILED,
  GET_WEEKLY_PERFORMANCE_CLIENT_SUCCESS,
  GET_WEEKLY_PERFORMANCE_GUEST_BEGIN,
  GET_WEEKLY_PERFORMANCE_GUEST_FAILED,
  GET_WEEKLY_PERFORMANCE_GUEST_SUCCESS,
  PAYPAL_SUBSCRIPTION_SUCCESS_PAYMENT,
  PAYPAL_SUCCESS_PAYMENT,
  PAYPAL_USER_TOP_UP_BEGIN,
  PAYPAL_USER_TOP_UP_FAILED,
  PAYPAL_USER_TOP_UP_SUBSCRIPTION_BEGIN,
  PAYPAL_USER_TOP_UP_SUBSCRIPTION_FAILED,
  PAYPAL_USER_TOP_UP_SUBSCRIPTION_SUCCESS,
  PAYPAL_USER_TOP_UP_SUCCESS,
  STRIPE_USER_TOP_UP_BEGIN,
  STRIPE_USER_TOP_UP_FAILED,
  STRIPE_USER_TOP_UP_SUBSCRIPTION_BEGIN,
  STRIPE_USER_TOP_UP_SUBSCRIPTION_FAILED,
  STRIPE_USER_TOP_UP_SUBSCRIPTION_SUCCESS,
  STRIPE_USER_TOP_UP_SUCCESS,
  SUBMIT_CONVERT_MEDALS_BEGIN,
  SUBMIT_CONVERT_MEDALS_FAILED,
  SUBMIT_CONVERT_MEDALS_SUCCESS,
  UPDATE_TRANSACTIONS_STATUS_BEGIN,
  UPDATE_TRANSACTIONS_STATUS_FAILED,
  UPDATE_TRANSACTIONS_STATUS_SUCCESS,
} from "./constants";

export const stripeUserTopUpBegin = (data) => ({
  type: STRIPE_USER_TOP_UP_BEGIN,
  payload: data,
});

export const stripeUserTopUpSuccess = (data) => ({
  type: STRIPE_USER_TOP_UP_SUCCESS,
  payload: data,
});

export const stripeUserTopUpFailed = (error) => ({
  type: STRIPE_USER_TOP_UP_FAILED,
  payload: error,
});

export const stripeUserTopUpSubscriptionBegin = (data) => ({
  type: STRIPE_USER_TOP_UP_SUBSCRIPTION_BEGIN,
  payload: data,
});

export const stripeUserTopUpSubscriptionSuccess = (data) => ({
  type: STRIPE_USER_TOP_UP_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const stripeUserTopUpSubscriptionFailed = (error) => ({
  type: STRIPE_USER_TOP_UP_SUBSCRIPTION_FAILED,
  payload: error,
});

export const paypalUserTopUpBegin = (data) => ({
  type: PAYPAL_USER_TOP_UP_BEGIN,
  payload: data,
});

export const paypalUserTopUpSuccess = (data) => ({
  type: PAYPAL_USER_TOP_UP_SUCCESS,
  payload: data,
});

export const paypalUserTopUpFailed = (error) => ({
  type: PAYPAL_USER_TOP_UP_FAILED,
  payload: error,
});

export const paypalSuccessPaymentCall = (data) => ({
  type: PAYPAL_SUCCESS_PAYMENT,
  payload: data,
});

export const paypalUserTopUpSubscriptionBegin = (data) => ({
  type: PAYPAL_USER_TOP_UP_SUBSCRIPTION_BEGIN,
  payload: data,
});

export const paypalUserTopUpSubscriptionSuccess = (data) => ({
  type: PAYPAL_USER_TOP_UP_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const paypalUserTopUpSubscriptionFailed = (error) => ({
  type: PAYPAL_USER_TOP_UP_SUBSCRIPTION_FAILED,
  payload: error,
});

export const paypalSubscriptionSuccessPaymentCall = (data) => ({
  type: PAYPAL_SUBSCRIPTION_SUCCESS_PAYMENT,
  payload: data,
});

export const fetchUserTransactionsBegin = (data) => ({
  type: FETCH_USER_TRANSACTIONS_BEGIN,
  payload: data,
});

export const fetchUserTransactionsSuccess = (data) => ({
  type: FETCH_USER_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const fetchUserTransactionsFailed = (error) => ({
  type: FETCH_USER_TRANSACTIONS_FAILED,
  payload: error,
});

export const fetchTransactionDetailsBegin = (data) => ({
  type: FETCH_TRANSACTION_DETAILS_BEGIN,
  payload: data,
});

export const fetchTransactionDetailsSuccess = (data) => ({
  type: FETCH_TRANSACTION_DETAILS_SUCCESS,
  payload: data,
});

export const fetchTransactionDetailsFailed = (error) => ({
  type: FETCH_TRANSACTION_DETAILS_FAILED,
  payload: error,
});

export const fetchLatestTransactionsBegin = () => ({
  type: FETCH_LATEST_TRANSACTIONS_BEGIN,
});

export const fetchLatestTransactionsSuccess = (data) => ({
  type: FETCH_LATEST_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const fetchLatestTransactionsFailed = (error) => ({
  type: FETCH_LATEST_TRANSACTIONS_FAILED,
  payload: error,
});

export const fetchWineCollectionBegin = () => ({
  type: FETCH_WINE_COLLECTION_BEGIN,
});

export const fetchWineCollectionSuccess = (data) => ({
  type: FETCH_WINE_COLLECTION_SUCCESS,
  payload: data,
});

export const fetchWineCollectionFailed = (error) => ({
  type: FETCH_WINE_COLLECTION_FAILED,
  payload: error,
});

export const fetchWinesBegin = () => ({
  type: FETCH_WINES_BEGIN,
});

export const fetchWinesSuccess = (data) => ({
  type: FETCH_WINES_SUCCESS,
  payload: data,
});

export const fetchWinesFailed = (error) => ({
  type: FETCH_WINES_FAILED,
  payload: error,
});

export const fetchWineDetailsBegin = (data) => ({
  type: FETCH_WINE_DETAILS_BEGIN,
  payload: data,
});

export const fetchWineDetailsSuccess = (data) => ({
  type: FETCH_WINE_DETAILS_SUCCESS,
  payload: data,
});

export const fetchWineDetailsFailed = (error) => ({
  type: FETCH_WINE_DETAILS_FAILED,
  payload: error,
});

export const fetchClientBalanceSimulationBegin = () => ({
  type: FETCH_CLIENT_BALANCE_SIMULATION_BEGIN,
});

export const fetchClientBalanceSimulationSuccess = (data) => ({
  type: FETCH_CLIENT_BALANCE_SIMULATION_SUCCESS,
  payload: data,
});

export const fetchClientBalanceSimulationFailed = (error) => ({
  type: FETCH_CLIENT_BALANCE_SIMULATION_FAILED,
  payload: error,
});

export const fetchOutlookSimulationBegin = (isLoggedIn: boolean) => ({
  type: FETCH_OUTLOOK_SIMULATION_BEGIN,
  payload: isLoggedIn,
});

export const fetchOutlookSimulationSuccess = (data) => ({
  type: FETCH_OUTLOOK_SIMULATION_SUCCESS,
  payload: data,
});

export const fetchOutlookSimulationFailed = (error) => ({
  type: FETCH_OUTLOOK_SIMULATION_FAILED,
  payload: error,
});

export const fetchGeographicAllocationBegin = () => ({
  type: FETCH_GEOGRAPHIC_ALLOCATION_BEGIN,
});

export const fetchGeographicAllocationSuccess = (data) => ({
  type: FETCH_GEOGRAPHIC_ALLOCATION_SUCCESS,
  payload: data,
});

export const fetchGeographicAllocationFailed = (error) => ({
  type: FETCH_GEOGRAPHIC_ALLOCATION_FAILED,
  payload: error,
});

export const fetchWinePerformanceBegin = (id) => ({
  type: FETCH_WINE_PERFORMANCE_BEGIN,
  payload: id,
});

export const fetchWinePerformanceSuccess = (data) => ({
  type: FETCH_WINE_PERFORMANCE_SUCCESS,
  payload: data,
});

export const fetchWinePerformanceFailed = (error) => ({
  type: FETCH_WINE_PERFORMANCE_FAILED,
  payload: error,
});

export const getWeeklyPerformanceGuestBegin = () => ({
  type: GET_WEEKLY_PERFORMANCE_GUEST_BEGIN,
});

export const getWeeklyPerformanceGuestSuccess = (data) => ({
  type: GET_WEEKLY_PERFORMANCE_GUEST_SUCCESS,
  payload: data,
});

export const getWeeklyPerformanceGuestFailed = (error) => ({
  type: GET_WEEKLY_PERFORMANCE_GUEST_FAILED,
  payload: error,
});

export const getWeeklyPerformanceClientBegin = () => ({
  type: GET_WEEKLY_PERFORMANCE_CLIENT_BEGIN,
});

export const getWeeklyPerformanceClientSuccess = (data) => ({
  type: GET_WEEKLY_PERFORMANCE_CLIENT_SUCCESS,
  payload: data,
});

export const getWeeklyPerformanceClientFailed = (error) => ({
  type: GET_WEEKLY_PERFORMANCE_CLIENT_FAILED,
  payload: error,
});

export const updateTransactionsStatusBegin = () => ({
  type: UPDATE_TRANSACTIONS_STATUS_BEGIN,
});

export const updateTransactionsStatusSuccess = (data) => ({
  type: UPDATE_TRANSACTIONS_STATUS_SUCCESS,
  payload: data,
});

export const updateTransactionsStatusFailed = (error) => ({
  type: UPDATE_TRANSACTIONS_STATUS_FAILED,
  payload: error,
});

export const clearGraphDataLoggedInState = () => ({
  type: CLEAR_LOGGED_IN_GRAPH_DATA,
});

export const clearTransactions = () => ({
  type: CLEAR_TRANSACTIONS,
});

export const submitConvertMedalsBegin = (data: {
  type: string;
  medals: number;
}) => ({
  type: SUBMIT_CONVERT_MEDALS_BEGIN,
  payload: data,
});

export const submitConvertMedalsSuccess = (data) => ({
  type: SUBMIT_CONVERT_MEDALS_SUCCESS,
  payload: data,
});

export const submitConvertMedalsFailed = (error) => ({
  type: SUBMIT_CONVERT_MEDALS_FAILED,
  payload: error,
});
