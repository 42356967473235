import { useTranslation } from "react-i18next";
import styles from "./ConvertMedalsNotAvailable.module.scss";
import { GlobalIState } from "types/RootState";
import { useAppDispatch, useAppSelector } from "hooks";
import { getDefaultColour } from "utils/utils";

import { useNavigate, useSearchParams } from "react-router-dom";
import { GreenSuccessRoundedIcon, InfoIcon } from "assets/icons/icons";
import ButtonComponent from "components/Button/Button.component";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

export default function ConvertMedalsNotAvailable() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const { t: translations } = useTranslation();
  const [searchParams] = useSearchParams();
  const typeParam = searchParams.get("type");

  const handleClose = () => {
    if (isMobile) navigate("/wallet");
    else {
      if (typeParam === "topup") {
        navigate("/investments/topup");
      } else window.location.reload();
    }
  };

  return (
    <div
      className={styles.container}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div>
        <h1>{translations("label.convert")}</h1>
        <span>{translations("label.convert_medals_subtitle")}</span>
        <br />
        <span>{translations("label.after_3months_of_games_activity")}</span>
      </div>
      <ButtonComponent
        label={translations("button.close")}
        class={"btn btn-primary"}
        handleClick={() => handleClose()}
      />
    </div>
  );
}
