import api from "./api";

const API_BASE_URL =
  "https://api.openai.com/v1/assistants/asst_vhIHtjYjLW84eyyZOBv8XG23";
const PRIVATE_KEY =
  "Bearer sk-proj-wPn9_oRDDyBKKq8W4N2qIoEUnscI2zGOQbruDgV8Lw-EScRTH1NbPEJff4hOhtu89NgXKzTbKT3BlbkFJ4ajdhRdPqdQ96PoYHQW2T1JzPQAa1Mclc1TeYu_plYu9pINMCD4H09U9jHaCSAtaODBEc6jhU";

export const callAIAsisstant = (question: string) => {
  return api.post("/chat/ask-dino", { message: question });
};

export const getStreamFeed = (data: { isLoggedIn: boolean; lang: string }) => {
  return api.get(
    data.isLoggedIn
      ? `/cards/${data.lang}`
      : `/cards/offline-cards/${data.lang}`
  );
};

export const submitStreamPoll = (data: { card_id: number; answer: string }) => {
  return api.post("/cards/submit", {
    card_id: data.card_id,
    answer: data.answer,
  });
};

export const saveAsisstantChatToStream = (data: {
  question: string;
  answer: string;
}) => {
  return api.post("/cards/save/chat", {
    question: data.question,
    answer: data.answer,
  });
};
