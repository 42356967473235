const scope = "vinfinity/home";

export const CALL_AI_ASISSTANT_BEGIN = `${scope}/CALL_AI_ASISSTANT_BEGIN`;
export const CALL_AI_ASISSTANT_SUCCESS = `${scope}/CALL_AI_ASISSTANT_SUCCESS`;
export const CALL_AI_ASISSTANT_FAILED = `${scope}/CALL_AI_ASISSTANT_FAILED`;

export const GET_STREAM_FEED_BEGIN = `${scope}/GET_STREAM_FEED_BEGIN`;
export const GET_STREAM_FEED_SUCCESS = `${scope}/GET_STREAM_FEED_SUCCESS`;
export const GET_STREAM_FEED_FAILED = `${scope}/GET_STREAM_FEED_FAILED`;

export const SUBMIT_STREAM_POLL_BEGIN = `${scope}/SUBMIT_STREAM_POLL_BEGIN`;
export const SUBMIT_STREAM_POLL_SUCCESS = `${scope}/SUBMIT_STREAM_POLL_SUCCESS`;
export const SUBMIT_STREAM_POLL_FAILED = `${scope}/SUBMIT_STREAM_POLL_FAILED`;

export const CLEAR_DINO_AI_RESPONSE = `${scope}/CLEAR_DINO_AI_RESPONSE`;

export const SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN = `${scope}/SAVE_ASISSTANT_CHAT_TO_STREAM_BEGIN`;
export const SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS = `${scope}/SAVE_ASISSTANT_CHAT_TO_STREAM_SUCCESS`;
export const SAVE_ASISSTANT_CHAT_TO_STREAM_FAILED = `${scope}/SAVE_ASISSTANT_CHAT_TO_STREAM_FAILED`;
