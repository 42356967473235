import { useTranslation } from "react-i18next";
import styles from "./LoginPasscode.module.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { GlobalIState } from "types/RootState";

import PasscodeInput from "components/PasscodeInput/PasscodeInput.component";
import { useEffect, useState } from "react";
import {
  HidePasswordIcon,
  PreviousIcon,
  ShowPasswordIcon,
} from "assets/icons/icons";
import { getDefaultColour } from "utils/utils";
import ButtonComponent from "components/Button/Button.component";
import { openToaster } from "App/actions";
import { logInBegin } from "pages/Authentication/actions";
import StorageService from "services/storage.service";
import { LOG_IN_SUCCESS } from "pages/Authentication/constants";

export default function LoginPasscode() {
  const {
    action: { actionName, isLoading, error },
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t: translations } = useTranslation();
  let isMobile = useCheckMobileScreen();
  const [searchParams] = useSearchParams();
  const [passcode, setPasscode] = useState("");
  const [showPasscode, setShowPasscode] = useState(true);
  const emailParam = searchParams.get("email");
  const eventIdParam = searchParams.get("eventId");
  let lastRoute = location.state?.lastRoute;
  const lastRouteCookie = StorageService.getDataByKey("lastRouteCookie");

  const getStoredLanguage = () => {
    return localStorage.getItem("lang");
  };

  const isLoggedIn = () => StorageService.getDataByKey("token");

  const handleSave = () => {
    if (passcode?.length !== 4) {
      dispatch(
        openToaster("ERROR", translations("error.please_fill_passcode_field"))
      );
      return;
    }

    dispatch(
      logInBegin(
        emailParam.replaceAll(" ", "+"),
        passcode,
        "",
        "",
        "",
        "email",
        ""
      )
    );
  };

  useEffect(() => {
    if (actionName === LOG_IN_SUCCESS) {
      if (eventIdParam) {
        navigate(`/events?id=${eventIdParam}`);
      } else {
        if (lastRoute || lastRouteCookie) {
          navigate(lastRouteCookie ? lastRouteCookie : lastRoute);
          StorageService.deleteCookie("lastRouteCookie");
        } else {
          navigate("/");
        }
      }
    }
  }, [actionName, lastRouteCookie]);

  useEffect(() => {
    if (error === "error.send_2fa_two_factor_code") {
      let data = {
        user: emailParam.replaceAll(" ", "+"),
        password: passcode,
        country_phone: "",
        phone: "",
        google_auth_code: "",
        login_method: "email",
      };
      StorageService.setKeyValue("loginTwoFactorData", data);
      navigate("/login/two-factor");
    }
  }, [error]);

  // useEffect(() => {
  //   if (isLoggedIn() && !lastRouteCookie) {
  //     navigate("/");
  //   }
  // }, [isLoggedIn(), actionName]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div
          className={styles.previousIcon}
          onClick={() => {
            navigate("/login");
          }}
        >
          <PreviousIcon color={getDefaultColour(defaultColour)} />
        </div>
      </div>

      <div className={styles.content}>
        <h1>{translations("button.log_in")}</h1>

        <div className={styles.passcodeContainer}>
          <h3>{translations("label.enter_4_digit_passcode")}</h3>
          <div
            className={styles.showPasscodeLabel}
            onClick={() => setShowPasscode(!showPasscode)}
          >
            <span>
              {translations(
                showPasscode ? "label.hide_passcode" : "label.show_passcode"
              )}
            </span>
            <div>
              {showPasscode ? (
                <ShowPasswordIcon color={"#000"} />
              ) : (
                <HidePasswordIcon color={"#000"} />
              )}
            </div>
          </div>
          <PasscodeInput
            length={4}
            showPasscode={showPasscode}
            onChange={setPasscode}
          />
          <div className={styles.bottomPart}>
            <div
              className={styles.forgotPasscodeLabel}
              onClick={() => navigate("/forgot-passcode")}
            >
              <span>{translations("label.forgot_passcode?")}</span>
            </div>
            {/* <div
              className={styles.showPasscodeLabel}
              onClick={() => setShowPasscode(!showPasscode)}
            >
              <span>
                {translations(
                  showPasscode ? "label.hide_passcode" : "label.show_passcode"
                )}
              </span>
              <div>
                {showPasscode ? (
                  <ShowPasswordIcon color={"#000"} />
                ) : (
                  <HidePasswordIcon color={"#000"} />
                )}
              </div>
            </div> */}
          </div>
        </div>

        <div className={styles.saveButton}>
          <ButtonComponent
            label={translations("button.submit")}
            class={"btn btn-primary"}
            isLoading={isLoading}
            handleClick={() => handleSave()}
          />
        </div>
      </div>
    </div>
  );
}
