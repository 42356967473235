import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalIState } from "types/RootState";
import NoVinfinityWinesImageEN from "assets/images/en-empty-wines-desktop.png";
import NoVinfinityWinesImageMobileEN from "assets/images/en-empty-wines-mobile.png";
import NoVinfinityWinesImageDE from "assets/images/de-empty-wines-desktop.png";
import NoVinfinityWinesImageMobileDE from "assets/images/de-empty-wines-mobile.png";
import NoVinfinityWinesImageES from "assets/images/es-empty-wines-desktop.png";
import NoVinfinityWinesImageMobileES from "assets/images/es-empty-wines-mobile.png";
import NoVinfinityWinesImageFR from "assets/images/fr-empty-wines-desktop.png";
import NoVinfinityWinesImageMobileFR from "assets/images/fr-empty-wines-mobile.png";
import NoVinfinityWinesImageIT from "assets/images/it-empty-wines-desktop.png";
import NoVinfinityWinesImageMobileIT from "assets/images/it-empty-wines-mobile.png";

import styles from "./NoVinfinityWines.module.scss";
import ButtonComponent from "components/Button/Button.component";

function NoVinfinityWines() {
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const { t: translations } = useTranslation();
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const getLanguage = () => localStorage.getItem("lang");

  const getContentImage = () => {
    switch (getLanguage()) {
      case "en":
        return isMobile
          ? NoVinfinityWinesImageMobileEN
          : NoVinfinityWinesImageEN;
        break;
      case "de":
        return isMobile
          ? NoVinfinityWinesImageMobileDE
          : NoVinfinityWinesImageDE;
        break;
      case "es":
        return isMobile
          ? NoVinfinityWinesImageMobileES
          : NoVinfinityWinesImageES;
        break;
      case "fr":
        return isMobile
          ? NoVinfinityWinesImageMobileFR
          : NoVinfinityWinesImageFR;
        break;
      case "it":
        return isMobile
          ? NoVinfinityWinesImageMobileIT
          : NoVinfinityWinesImageIT;
        break;
      default:
        return isMobile
          ? NoVinfinityWinesImageMobileEN
          : NoVinfinityWinesImageEN;
        break;
    }
  };

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className={styles.container}>
      <h2>{translations("title.no_vinfinity_wines")}</h2>
      <span>{translations("subtitle.no_vinfinity_wines")}</span>
      <img src={getContentImage()} alt="No wines" />

      <ButtonComponent
        label={translations("label.make_your_first_purchase")}
        class={"btn btn-primary"}
        handleClick={() => handleClick()}
      />
    </div>
  );
}

export default NoVinfinityWines;
